<template>
  <div class="profileMain container px-0">
    <div class="main_header">
      <div class="profile"></div>
      <div class="profile_img">
        <img :src="profileImg" alt="" id="previewImg" v-if="profileImg" />
        <img src="../assets/user_vector.png" alt="" id="previewImg" v-else />
        <input
          id="js-file-uploader"
          class="hidden"
          name="profile-picture"
          type="file"
          accept="image/*"
          @change="uploadProfile($event)"
        />
      </div>
    </div>
    <div class="container">
      <div class="heading">
        <p class="title text-muted mb-0">{{ firstName }} {{ lastName }}</p>
        <p class="title mb-2">Health Information</p>
        <div class="btn btn-outline-primary mobUploadBtn" @click="uploadFile">
          UPLOAD FILE
        </div>
      </div>

      <div class="contentContainer">
        <div class="results">Display last Covid test results</div>
        <div v-if="!data.length">
          <p class="mt-5 text-center">No tests added...</p>
        </div>
        <div v-else>
          <div class="qa" v-for="(data, dataIndex) in data" :key="dataIndex">
            <div class="question">
              {{ dataIndex + 1 }}. Covid 19 {{ data.vaccine }} Vaccine
            </div>
            <div class="hospital">
              <span>Hospital:</span> {{ data.institute }}
            </div>
            <div class="date"><span>Date:</span> {{ data.date }}</div>
            <div class="status" v-if="data.status == 'negative'">
              Status:<span class="green"> {{ data.status }}</span
              ><b-icon icon="check2" class="text-success"></b-icon>
            </div>
            <div class="status" v-else-if="data.status == 'positive'">
              Status:<span class="red"> {{ data.status }}</span
              ><b-icon icon="x" class="text-danger"></b-icon>
            </div>
            <div class="status" v-else>
              Status:<span class="text-warning"> {{ data.status }}</span>
              <b-icon icon="exclamation" class="text-warning"></b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      configData: {},
      profileImg: "",
    };
  },
  created() {
   
    if (this.$store.getters.getAuth == false) {
      this.$router.push("/login");
    }

    var storeData = this.$store.getters.getData;
    console.log(storeData);
    this.firstName = storeData.firstname;
    this.lastName = storeData.lastname;
    this.profileImg = storeData.profileUrl;

    axios.get("https://api.vaxtraxglobal.com/api/v1/config/1").then(({ data }) => {
      this.configData = data;
      console.log(this.configData);
      storeData.healthInfo.forEach((element) => {
        var dataToPush = {};

        this.configData.institute.forEach((institute) => {
          if (element.instituteId === institute.id) {
            dataToPush.institute = institute.name;
          }
        });
        this.configData.vaccineType.forEach((vaccine) => {
          if (element.type === vaccine.id) {
            dataToPush.vaccine = vaccine.type;
          }
        });

        dataToPush.date = this.formatDate(element.date);
        dataToPush.status = element.status;

        this.data.push(dataToPush);
      });
      console.log(this.data);
    });
  },
  methods: {
    formatDate(input) {
      var date = new Date(input);

      var result =
        "" +
        (date.getDate() > 9 ? "" : "0") +
        date.getDate() +
        "/" +
        (date.getMonth() + 1 > 9 ? "" : "0") +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear();

      return result;
    },
    uploadFile() {
      this.$router.push("/health-info-file-upload");
    },
    uploadProfile(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById("previewImg").src = reader.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    onSubmit() {
      alert("submitted");
    },
  },
};
</script>

<style lang="scss" scoped>
.profileMain {
  height: 100%;
  min-height: 60vh;
  padding-bottom: 3vh;
  margin: 3vh auto;
  box-shadow: inset 12px 12px 51px #00000029, 20px 20px 20px #00000029;

  .main_header {
    position: relative;
    height: 10%;
    padding-top: 35px;

    .profile {
      width: 100%;
      height: 120px;
      background: #005eaa33;
      clip-path: polygon(0 0, 0 35%, 50% 100%, 100% 35%, 100% 0%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .profile_img {
      margin: auto;
      width: 120px;
      height: 120px;
      border: 5px solid #fafafa;
      border-radius: 50%;
      background-position: center;
      background-size: contain;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .hidden {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 5rem;
        max-width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .heading {
    position: relative;

    .title {
      text-align: center;
      font-weight: bolder;
    }
    .mobUploadBtn {
      position: absolute;
      top: 0;
      right: 10px;
      border-radius: 25px;
      padding: 5px 20px;
      font-size: 12px;
      font-weight: 600;

      @media (max-width: 500px) {
        right: 0;
        bottom: -40px;
        top: auto;
        padding: 5px 10px;
      }
    }
  }
  .contentContainer {
    .results {
      margin: 1vh 3vw;
      font: normal normal bold 24px/29px Proxima Nova;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .qa {
      margin: 2vh 3vw;
      border-bottom: 1px solid #00000029;
      padding-bottom: 1vh;

      .question {
        text-align: left;
        font: normal normal bold 19px/30px Proxima Nova;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        margin-top: 1vh;

        .hospital {
          text-align: left;
          font: normal normal normal 16px/24px Proxima Nova;
          letter-spacing: 0px;
          color: #6f6f6f;

          > span {
            text-align: left;
            font: normal normal 600 17px/24px Proxima Nova;
            letter-spacing: 0px;
            color: #333333;
          }
        }

        .date {
          text-align: left;
          font: normal normal normal 16px/24px Proxima Nova;
          letter-spacing: 0px;
          color: #6f6f6f;

          > span {
            font: normal normal 600 17px/24px Proxima Nova;
            letter-spacing: 0px;
            color: #333333;
          }
        }
      }
      .status {
        text-align: left;
        font: normal normal 600 17px/24px Proxima Nova;
        letter-spacing: 0px;
        color: #333333;
        width: 50%;
        min-width: 250px;
        position: relative;

        .b-icon {
          font-size: 2rem;
          position: absolute;
          right: 0;
        }
        .green {
          color: #23b800;
        }
        .red {
          color: #ff0707;
        }
      }
    }

    .last {
      border-bottom: 1px solid transparent;
    }

    @media (max-width: 500px) {
      padding-top: 30px;
    }
  }
}
</style>
